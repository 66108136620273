import React, { useCallback, useEffect, useState } from 'react'

import style from './ManageProfile.module.scss'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import {
  AuthService,
  UserService,
  LogService,
  LogDocumentType,
  UserType,
  Environment
} from '@digitalworkflow/dwloginclient'
import PSL from '../../utils/pslInstance'

const authService = AuthService.instance()
LogService.setAuthServiceInstance(authService)
UserService.setAuthServiceInstance(authService)
const logService = new LogService()
const userService = new UserService()
interface Props {
  userInfo: UserType | null
}

const columnDefs = [
  {
    field: 'message',
    headerName: 'Description',
    sortable: true,
    unSortIcon: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    flex: 1,
    floatingFilter: true
  },
  {
    field: 'created_by',
    headerName: 'Changed By',
    sortable: true,
    unSortIcon: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    flex: 1,
    floatingFilter: true
  },
  {
    field: 'created_dt',
    headerName: 'Date',
    sortable: true,
    unSortIcon: true,
    resizable: true,
    filter: 'agDateColumnFilter',
    minWidth: 140,
    flex: 1,
    floatingFilter: true,
    valueFormatter: (params: any) => {
      return params.data.created_dt
        ? moment(params.data.created_dt).format('LLL')
        : ''
    },
    cellRenderer: (params: any) => {
      return params.data.created_dt
        ? moment(params.data.created_dt).format('LLL')
        : ''
    },
    filterParams: {
      maxValidDate: new Date(),
      comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        if (!cellValue) {
          return -1
        }
        const date = new Date(cellValue)
        const year = Number(date.getFullYear())
        const month = Number(date.getMonth())
        const day = Number(date.getDate())
        const cellDate = new Date(year, month, day)

        if (cellDate < filterLocalDateAtMidnight) {
          return -1
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1
        }

        return 0
      }
    }
  }
]

const ActivityLog = ({ userInfo }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [logs, setLogs] = useState<LogDocumentType[]>([])

  const getLogs = useCallback(async () => {
    try {
      setIsLoading(true)
      const [logsResult, usersResult] = await Promise.all([
        logService.getAllLogs({
          filter: {
            event_type: 'activity',
            env: PSL.GetEnvironment() as Environment,
            reference_id: userInfo?.id
          },
          sort: [{ created_dt: 'DESC' }]
        }),
        userService.getAllUser()
      ])

      if (!logsResult.is_error && !usersResult.is_error) {
        const logsData = logsResult.data || []
        const usersData = usersResult.data || []
        const updatedLogs = logsData.map((log: any) => {
          const user = usersData.find(
            (user: UserType) => user.id === log.created_by
          )
          log.created_by = user
            ? `${user.first_name} ${user.last_name}`
            : 'Unknown User'
          return log
        })
        setLogs(updatedLogs)
      }

      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [setIsLoading, setLogs, logService, userInfo, userService])

  useEffect(() => {
    if (userInfo) {
      getLogs()
    }
  }, [userInfo])

  return (
    <div className={style.innerContainer}>
      <div className={style.gridPortalContainer}>
        {isLoading ? (
          <div className='flex justify-center'>
            <Spinner />
          </div>
        ) : (
          <LoginPortalGrid columnDefs={columnDefs} rowData={logs} />
        )}
      </div>
    </div>
  )
}
export default ActivityLog
