import { LocalSettings } from './LocalSettings'
import PSL from './pslInstance'

export const exportData = async (urlPath: string, filename: string) => {
  const axios = await PSL.GetAxiosInstance('api')

  axios.defaults.headers.common['X-Auth-Token'] =
    LocalSettings.getAuthToken() ?? ''

  try {
    const response = await axios.get(urlPath, {
      responseType: 'blob'
    })

    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    })
    const link = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    link.href = blobUrl

    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}
