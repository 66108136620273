import PSL from './pslInstance'

export const getCronJobs = async (
  size: number,
  from: number,
  filter: string = '',
  sort: string = ''
) => {
  const baseURL = await PSL.GetSetting('searchserverapiurl', '')
  const axios = await PSL.GetAxiosInstance('api')
  axios.defaults.baseURL = baseURL
  const url = `cron-history/search`

  const data = {
    size: size,
    from: from,
    ...(filter ? { filter } : {}),
    ...(sort ? { sort } : {})
  }

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching cron jobs:', error)
    throw error
  }
}
