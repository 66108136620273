import PSL from './pslInstance'

export interface ISentEmailGetOptions {
  page?: number
  limit?: number
  filter?: {
    subject?: string
    subject_type?: string
    target?: string
    target_type?: string
    timestamp?: string
    timestamp_type?: string
  }
  sort?: string
  sortOrder?: string
}

/**
 * Get Failed Emails Data from API
 *
 * @param param0
 * @returns
 */
export const getFailedEmails = async ({
  page = 1,
  limit = 50,
  filter = {},
  sort,
  sortOrder
}: ISentEmailGetOptions = {}) => {
  const baseURL = await PSL.GetSetting('messageserverapiurl', '')
  const axios = await PSL.GetAxiosInstance('api')
  axios.defaults.baseURL = baseURL

  const params = new URLSearchParams({
    page: `${page}`,
    limit: `${limit}`,
    ...filter
  })

  if (sort) params.append('sort', sort)
  if (sortOrder) params.append('sortOrder', sortOrder)

  const url = `logs/failed-emails?${params.toString()}`

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching failed emails:', error)
    throw error
  }
}

/**
 * Fetches the email body of a failed email based on the provided ID.
 *
 * @param params - An object containing the email ID.
 * @param params._id - The ID of the failed email.
 *
 * @returns A promise that resolves to a JSON response containing the status and either the email body or an error message.
 *
 * @throws Will throw an error if the fetch operation fails.
 */
export const getFailedEmailBody = async ({ _id: id }: { _id: string }) => {
  const baseURL = await PSL.GetSetting('messageserverapiurl', '')
  const axios = await PSL.GetAxiosInstance('api')
  axios.defaults.baseURL = baseURL
  const url = `logs/failed-emails/body/${id}`

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching failed email body:', error)
    throw error
  }
}
