/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import style from './ManageProfile.module.scss'
import '../../style/roleContianer.scss'
import {
  AuthService,
  PortalService,
  PortalType,
  UserService,
  PermissionService,
  UserType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { Button, Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import ToggleButton from '../ToggleButton/ToggleButton'
import { sortComparator } from '../../utils/sortArray'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import { compareArrays } from '../../utils/compareArray'
import { LocalSettings } from '../../utils/LocalSettings'
interface IPortals {
  profile: UserType | null
  getUser: () => void
  isAllowedToModify?: boolean
  isReadOnlyAccess: boolean
}
type SortDirection = 'asc' | 'desc' | null

const Portals = ({
  profile,
  getUser,
  isAllowedToModify = true,
  isReadOnlyAccess
}: IPortals) => {
  const [enabledPortal, setEnabledPortal] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const authService = AuthService.instance()
  PortalService.setAuthServiceInstance(authService)
  PermissionService.setAuthServiceInstance(authService)
  const gridRef = useRef<any>(null)

  const portalService = new PortalService()
  const userService = new UserService()
  const permissionService = new PermissionService()

  UserService.setAuthServiceInstance(authService)

  const portalRef = useRef<PortalType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [bulkActionToggle, setBulkActionToggle] = useState<boolean>(false)
  const isProgrammaticClickRef = useRef(false)

  useEffect(() => {
    if (gridRef.current && !isProgrammaticClickRef.current) {
      const toggleButtonLabel = document.querySelector(
        '.floatingFilterToggleButtonPortals label'
      ) as HTMLElement
      if (toggleButtonLabel) {
        // Programmatic click triggered
        isProgrammaticClickRef.current = true
        toggleButtonLabel.click() // Trigger click on the label
      }
    }
    setTimeout(() => {
      isProgrammaticClickRef.current = false
    }, 0) // Ensures it resets after the current event loop
  }, [bulkActionToggle])

  const isDisabled = useMemo(() => {
    return compareArrays(
      (profile?.portals_enabled as string[]) ?? [],
      enabledPortal
    )
  }, [enabledPortal, profile])

  useEffect(() => {
    const allPortalsEnabled =
      enabledPortal.length > 0 &&
      enabledPortal.length === portalRef.current.length
    setBulkActionToggle(allPortalsEnabled)
  }, [enabledPortal, portalRef])

  const columnDefs = useMemo(() => {
    return [
      {
        field: 'portal_name',
        headerName: 'Portal Name',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        minWidth: 140,
        flex: 2,
        floatingFilter: true,
        comparator: sortComparator(null)
      },
      ...(isAllowedToModify
        ? [
            {
              field: 'action',
              headerName: 'Action',
              filter: 'agNumberColumnFilter',
              minWidth: 140,
              flex: 1,
              resizable: true,
              floatingFilter: true,
              floatingFilterComponent: ToggleButton,
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                className: 'floatingFilterToggleButtonPortals',
                isChecked: bulkActionToggle,
                disabled: isReadOnlyAccess,
                onChange: (e: any) => handleBulkActionToggle(e.target.checked)
              },
              sort: 'desc' as SortDirection,
              valueGetter: (params: any) =>
                enabledPortal.includes(params.data.id),
              cellRenderer: (params: any) => (
                <div
                  className={`flex justify-between items-center ${style.actionContainer}`}
                >
                  <ToggleButton
                    name='portal'
                    disabled={isReadOnlyAccess}
                    isChecked={enabledPortal.includes(params.data.id)}
                    onChange={(event: any) =>
                      handleToggleActive(params.data.id, event.target.checked)
                    }
                  />
                </div>
              )
            }
          ]
        : [])
    ]
  }, [isAllowedToModify, enabledPortal, isReadOnlyAccess])

  useEffect(() => {
    setEnabledPortal(profile?.portals_enabled ?? [])
  }, [profile])

  const handleBulkActionToggle = (value: boolean) => {
    setBulkActionToggle(value)
    if (!isProgrammaticClickRef.current) {
      const allPortalIds = portalRef.current.map((data) => data.id) as string[]

      // Enable or disable all portals
      setEnabledPortal(value ? allPortalIds : [])
    }

    setTimeout(() => {
      isProgrammaticClickRef.current = false
    }, 0)
  }

  const getAllPerrmissions = useCallback(
    async (userId: string) => {
      setIsLoading(true)
      const result = await permissionService.getAllPermissions()
      setIsLoading(false)
      const userAssignedPermissionDetails = result.data?.filter(
        (permission) => userId === permission.user_id
      )
      return userAssignedPermissionDetails ?? []
    },
    [setIsLoading, permissionService]
  )

  useEffect(() => {
    getPortals()
  }, [])

  const handleToggleActive = useCallback(
    (id: string, isActive: boolean) => {
      setEnabledPortal((current) => {
        const updated = isActive
          ? [...current, id]
          : current.filter((r) => r !== id)

        // Check if all portals are enabled
        const allSelected =
          updated.length > 0 && updated.length === portalRef.current.length
        setBulkActionToggle(allSelected)

        return updated
      })
    },
    [enabledPortal, portalRef]
  )

  const handleUpdate = useCallback(async () => {
    setLoading(true)
    const _result: ApiResultType<UserType> = await userService.updateUserById(
      profile?.id ?? '',
      {
        ...profile,
        portals_enabled: enabledPortal
      }
    )
    if (!_result.is_error) {
      toast.success('Portals have been successfully updated')
    } else {
      toast.error(_result.message)

      const userData = JSON.parse(LocalSettings.getUser() ?? '')
      userData.permissions = await getAllPerrmissions(userData.id ?? '')
      LocalSettings.setUser(JSON.stringify(userData))
    }
    getUser()
    setLoading(false)
  }, [enabledPortal, setLoading])

  const getPortals = useCallback(async () => {
    try {
      setIsLoading(true)
      const _result: ApiResultType<PortalType[]> =
        await portalService.getAllPortals('restricted')
      if (!_result.is_error) {
        const data = _result.data ?? []
        if (data.length > 0) {
          const sortData = [...data].sort(sortComparator('portal_name'))
          portalRef.current = sortData
        } else {
          portalRef.current = []
        }

        if (
          profile?.portals_enabled &&
          profile?.portals_enabled.length > 0 &&
          profile?.portals_enabled.length === portalRef.current.length
        ) {
          handleBulkActionToggle(true)
        }
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }, [profile, setIsLoading])

  const portalsData = useMemo(() => {
    return isAllowedToModify
      ? portalRef.current
      : portalRef.current.filter((item: PortalType) =>
          enabledPortal.includes(item.id ?? '')
        )
  }, [isAllowedToModify, enabledPortal, portalRef.current])

  return (
    <div className={style.innerContainerWithButtons}>
      {isLoading ? (
        <div className='flex justify-center'>
          <Spinner />
        </div>
      ) : (
        <div className={style.gridPortalContainer}>
          {isAllowedToModify && (
            <div className='mb-2 mt-2 d-flex justify-content-end'>
              <div className='d-flex gap-2'>
                <Button
                  type='submit'
                  color='delete'
                  className='btn-sm'
                  onClick={handleUpdate}
                  disabled={isDisabled}
                >
                  <i className='fal fa-edit me-2' />
                  Update
                  {loading && <Spinner className={style.spinner} />}
                </Button>
                <Button
                  type='button'
                  color='edit'
                  className='btn-sm'
                  disabled={isDisabled}
                  onClick={() =>
                    setEnabledPortal(profile?.portals_enabled ?? [])
                  }
                >
                  <i className='fa fa-times me-2' />
                  Cancel
                </Button>
              </div>
            </div>
          )}
          <LoginPortalGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            rowData={portalsData}
          />
        </div>
      )}
    </div>
  )
}
export default Portals
