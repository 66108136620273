import { MenuItem, ProfileMenuItem } from '@digitalworkflow/dwreactcommon'
import { AuthService } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from '../utils/LocalSettings'
import PSL from '../utils/pslInstance'

const authService = AuthService.instance()

/** Return true if it is RPS / IO theme */
export const getIORPS = () => {
  if (window.location.hostname?.indexOf('rps') !== -1) return true
  if (window.location.hostname?.startsWith('io')) return true
  if (window.location.hash && window.location.hash.indexOf('rps') !== -1)
    return true
  if (window.location.pathname.startsWith('/io/')) return true
  return false
}

/** Return the Hub Portal Name */
export const getPortalName = () => {
  if (PSL.GetEnvironment() === 'prod') return 'lrLUHbGQ'
  if (PSL.GetEnvironment() === 'staging') {
    if (window.location.hostname?.indexOf('rps') !== -1) return '2YnHjPWr'
    return 'lrLUHbGQ'
  }
  return 'lrLUHbGQ'
}

export const portalName = getPortalName()

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const featureAccessMenuItems: Array<MenuItem> = [
  {
    item_type: 'Link',
    subtitle_en: 'Users',
    tooltip_en: 'Manage Users',
    icon: 'fas fa-user',
    route: '/hub/users',
    view_groups: '"PI, PSI'
  },
  {
    item_type: 'Link',
    subtitle_en: 'Workgroups',
    tooltip_en: 'Manage Workgroups',
    icon: 'fas fa-object-group',
    route: '/hub/workgroups',
    view_groups: '"PI, PSI'
  },
  {
    item_type: 'Link',
    subtitle_en: 'Roles',
    tooltip_en: 'Manage Roles',
    icon: 'fas fa-users',
    route: '/hub/roles',
    view_groups: '"PI, PSI'
  },
  {
    item_type: 'Link',
    subtitle_en: 'Cronjob History',
    tooltip_en: 'Manage Cronjob History',
    icon: 'fa-solid fa-rectangle-history',
    route: '/hub/cronjob-history',
    view_groups: 'PI, PSI'
  },
  {
    item_type: 'Link',
    subtitle_en: 'Portals',
    tooltip_en: 'Manage Portals',
    icon: 'fas fa-project-diagram',
    route: '/hub/portals',
    view_groups: '"PI, PSI'
  },
  {
    item_type: 'Link',
    subtitle_en: 'Claims Mapping',
    tooltip_en: 'Manage Claims Mapping',
    icon: 'fa fa-code-compare',
    route: '/hub/claims-mapping',
    view_groups: 'PI, PSI'
  },
  // {
  //   item_type: 'Link',
  //   subtitle_en: 'Email Templates',
  //   tooltip_en: 'Manage Email Templates',
  //   icon: 'fa fa-envelope-open',
  //   route: '/hub/email-templates',
  //   view_groups: 'PI, PSI'
  // },
  {
    item_type: 'Link',
    subtitle_en: 'System Messages',
    tooltip_en: 'Manage System Messages',
    icon: 'fa-solid fa-message',
    route: '/hub/system-messages',
    view_groups: 'PI, PSI'
  }
]

export const emailReportsMenu = {
  item_type: 'Menu',
  title_en: 'Email Reports',
  icon: 'fas fa-envelope',
  view_groups: 'PI, PSI',
  children: [
    {
      item_type: 'Link',
      subtitle_en: 'Sent Emails',
      tooltip_en: 'Sent Emails',
      icon: 'fas fa-envelope-circle-check',
      route: '/hub/email-reports/sent-emails',
      view_groups: 'PI, PSI'
    },
    {
      item_type: 'Link',
      subtitle_en: 'Blocked Emails',
      tooltip_en: 'Blocked Emails',
      icon: 'fas fa-notebook',
      route: '/hub/email-reports/blocked-emails',
      badge_count: null,
      view_groups: 'PI, PSI'
    },
    {
      item_type: 'Link',
      subtitle_en: 'Failed Emails',
      tooltip_en: 'Failed Emails',
      icon: 'fas fa-table',
      route: '/hub/email-reports/failed-emails',
      badge_count: null,
      view_groups: 'PI, PSI'
    }
  ]
}

export const menuItems: Array<MenuItem> = [
  {
    item_type: 'Menu',
    title_en: 'Admin Settings',
    icon: 'fas fa-gears',
    view_groups: 'PI, PSI',
    children: []
  },
  {
    item_type: 'Menu',
    title_en: 'Components',
    icon: 'fas fa-utensils',
    view_groups: 'PI, PSI',
    children: [
      {
        item_type: 'Link',
        subtitle_en: 'WorldClock',
        tooltip_en: 'Display WorldClock',
        icon: 'fas fa-clock',
        route: '/hub/clock',
        badge_count: 10,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Notepad',
        tooltip_en: 'Display Notepad',
        icon: 'fas fa-notebook',
        route: '/hub/notepad',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'AgGrid - Alpine',
        tooltip_en: 'Display AgGrid - Alpine Theme',
        icon: 'fas fa-table',
        route: '/hub/ag-grid',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'AgGrid - Balham',
        tooltip_en: 'Display AgGrid - Balham Theme',
        icon: 'fas fa-table',
        route: '/hub/ag-grid-balham',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Builder Data Grid',
        tooltip_en: 'Display Builder Data Grid',
        icon: 'fas fa-table',
        route: '/hub/builder-grid',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'RC-Dock Layout',
        tooltip_en: 'Display RC-Dock Layout',
        icon: 'fas fa-table-columns',
        route: '/hub/rc-dock',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Tree-view',
        tooltip_en: 'Display tree-view',
        icon: 'fas fa-folder-tree',
        route: '/hub/tree-view',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'DescriptiveButton',
        tooltip_en: 'Display DescriptiveButton',
        icon: 'fas fa-mobile-button',
        route: '/hub/descriptive-button',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'TaskList',
        tooltip_en: 'Display TaskList',
        icon: 'fa-solid fa-list-check',
        route: '/hub/task-list',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Miller Columns',
        tooltip_en: 'Display Miller Columns',
        icon: 'fas fa-columns-3',
        route: '/hub/miller-columns',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Buttons',
        tooltip_en: 'Display Buttons',
        icon: 'fa fa-rectangle',
        route: '/hub/buttons',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Toast Messages',
        tooltip_en: 'Display Toast Messages',
        icon: 'fa fa-message',
        route: '/hub/toast-messages',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Building Search',
        tooltip_en: 'Display Building Search',
        icon: 'fa fa-folder-magnifying-glass',
        route: '/hub/building-search',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'File Attachment',
        tooltip_en: 'Display File Attachment',
        icon: 'fa fa-file',
        route: '/hub/file-attachment',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Form Selection',
        tooltip_en: 'Display Form Selection',
        icon: 'fa fa-folder-magnifying-glass',
        route: '/hub/form-selection',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'DW Autocomplete',
        tooltip_en: 'Display Form Selection',
        icon: 'fa fa-folder-magnifying-glass',
        route: '/hub/dw-autocomplete',
        badge_count: null,
        view_groups: 'PI, PSI'
      },

      {
        item_type: 'Link',
        subtitle_en: 'Search Dropdown',
        tooltip_en: 'Display Search Dropdown',
        icon: 'fa fa-list-dropdown',
        route: '/hub/search-dropdown',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Select Dropdown',
        tooltip_en: 'Display Select Dropdown',
        icon: 'fa fa-folder-magnifying-glass',
        route: '/hub/select-dropdown',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Lookup Manager',
        tooltip_en: 'Display Lookup Manager',
        icon: 'fa-solid fa-list-check',
        route: '/hub/lookup-manager',
        badge_count: null,
        view_groups: 'PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Single File Attachment',
        tooltip_en: 'Display Single File Attachment',
        icon: 'fa-solid fa-upload',
        route: '/hub/single-file-attachment',
        badge_count: null,
        view_groups: 'PI, PSI'
      }
    ]
  }
]

export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: 'Action',
    text_en: 'My Profile',
    icon: 'fa fa-user',
    action: () => {
      window.location.href = `/hub/profile`
    }
  },
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: async () => {
      await authService.authLogout()
      LocalSettings.removeAuthToken()
      LocalSettings.removeUser()
      LocalSettings.removeMultiAuthToken()
      window.location.href = `/login/${portalName}`
    }
  }
]

export const colDef = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    floatingFilter: true
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: 'agTextColumnFilter',
    floatingFilter: true
  },
  {
    field: 'age',
    headerName: 'Age',
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: 'agTextColumnFilter',
    type: 'rightAligned',
    floatingFilter: true
  }
]

export const gridData = [
  { name: 'John', email: 'john@gmail.com', age: 30 },
  { name: 'John1', email: 'john@gmail.com', age: 30 }
]
