/* eslint-disable camelcase */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useRef
} from 'react'
import Role from '../../components/Role/Role'
import SearchInput from '../../components/SearchInput/SearchInput'
import style from './RoleList.module.scss'
import {
  AuthService,
  RoleService,
  RoleType
} from '@digitalworkflow/dwloginclient'
import { ApiResultType } from '@digitalworkflow/dwloginclient/lib/Auth/ApiResultType'
import { Button, Spinner } from 'reactstrap'
import { sortArrayByEveryPart } from '../../utils/sortArray'
import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import { roleSelectStyle } from './select.style'
import { exportData } from '../../utils/exportData'
interface IRoleList {
  handleAddRole?: () => void
  handleActiveRole: (role: RoleType) => void
  activeRole: RoleType | null
  allRoles: RoleType[]
  setAllRoles: (value: RoleType[]) => void
  searchInput: string
  setSearchInput: (value: string) => void
}

const RoleList = forwardRef((props: IRoleList, ref) => {
  const authService = AuthService.instance()

  RoleService.setAuthServiceInstance(authService)
  const roleService = new RoleService()

  const [roles, setRoles] = useState<RoleType[]>(props.allRoles)
  const [filterRoles, setFilterRoles] = useState<RoleType[]>(props.allRoles)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeRoleId, setActiveRoleId] = useState<string>(
    props.activeRole?.id ?? ''
  )
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (props.allRoles.length === 0) {
      getRoles()
    }
    if (searchInputRef.current) {
      searchInputRef.current.value = props.searchInput
    }
  }, [])
  useEffect(() => {
    if (props.searchInput !== '') {
      let _roles = [...roles]
      _roles = _roles.filter((item) =>
        item.formatted_role_name
          .toLowerCase()
          .includes(props.searchInput.toLowerCase())
      )
      setFilterRoles([..._roles])
    }
  }, [])

  useImperativeHandle(ref, () => ({
    getRoles
  }))

  const getRoles = useCallback(async () => {
    setIsLoading(true)
    const _role: ApiResultType<RoleType[]> = await roleService.getAllRole(
      'restricted'
    )
    setIsLoading(false)
    if (!_role.is_error && _role.data) {
      // Sorting the array by "Major :: Minor :: Section :: Part"
      const _roleList: RoleType[] = [..._role.data].sort(
        sortArrayByEveryPart('formatted_role_name')
      )
      let filteredRolesList = _roleList

      if (
        _roleList.length > 0 &&
        activeRoleId === '' &&
        _roleList[0].id !== undefined
      ) {
        setActiveRoleId(_roleList[0].id)
        props.handleActiveRole(_roleList[0])
      } else {
        const _role = _roleList.find((item) => item.id === activeRoleId)
        if (_role) props.handleActiveRole(_role)
      }
      // If search input is not empty then filter the role list
      if (searchInputRef.current && searchInputRef.current?.value !== '') {
        filteredRolesList = _roleList.filter((item: any) =>
          item.formatted_role_name
            .toLowerCase()
            .includes(searchInputRef.current?.value?.toLowerCase() || '')
        )
      }
      setRoles([..._roleList])
      setFilterRoles([...filteredRolesList])
      props.setAllRoles([..._roleList])
      // setSearchBlank()
    }
  }, [
    setRoles,
    setFilterRoles,
    setIsLoading,
    activeRoleId,
    setActiveRoleId,
    props.allRoles,
    searchInputRef
  ])

  // const setSearchBlank = useCallback(() => {
  //   if (searchInputRef.current) {
  //     searchInputRef.current.value = ''
  //   }
  // }, [searchInputRef])

  const handleActiveRole = useCallback(
    (workgroupRoleId: string | undefined) => {
      if (workgroupRoleId) {
        setActiveRoleId(workgroupRoleId)
        const _role = roles.find((item) => item.id === workgroupRoleId)
        if (_role) props.handleActiveRole(_role)
      }
    },
    [setActiveRoleId, roles]
  )

  const handleChange = useCallback(
    (value: string) => {
      props.setSearchInput(value)
      let _roles = [...roles]
      _roles = _roles.filter((item) =>
        item.formatted_role_name.toLowerCase().includes(value.toLowerCase())
      )
      setFilterRoles([..._roles])
    },
    [roles, setFilterRoles, props.searchInput, searchInputRef]
  )

  const handleChangeDropdown = useCallback((value: any) => {
    if (value.value === 'export') {
      exportData('roles/export', 'roles')
    }
  }, [])

  return (
    <div className={style.mainContainer}>
      <div className={style.header}>
        <div className='flex-1'>
          <SelectDropdown
            style={roleSelectStyle as any}
            options={[
              {
                label: `Active Roles (${roles.length})`,
                value: 'activeRole'
              },
              {
                label: 'Export All Roles',
                value: 'export'
              },
              {
                label: `Archived Roles `,
                value: null
              }
            ]}
            value={{
              label: `Active Roles (${roles.length})`,
              value: 'activeRole'
            }}
            onChange={handleChangeDropdown}
          />
        </div>
        <Button
          type='button'
          color='add'
          className='btn-sm  flex-1'
          onClick={props.handleAddRole}
        >
          + Add New Role
        </Button>
      </div>
      <SearchInput
        placeholder='Search Role'
        onChange={handleChange}
        ref={searchInputRef}
      />
      <div className={style.roleList}>
        {isLoading && (
          <div className='flex justify-center'>
            <Spinner />
          </div>
        )}
        {filterRoles.length > 0
          ? filterRoles.map((item, index) => (
              <Role
                title={item.formatted_role_name}
                groups={item?.work_groups?.length ?? 0}
                key={index}
                workgroupRoleId={item.id}
                activeWorkgroupRoleId={activeRoleId}
                handleActiveRole={handleActiveRole}
              />
            ))
          : !isLoading && (
              <div className='flex justify-center'>
                <span>No data found</span>
              </div>
            )}
      </div>
    </div>
  )
})
export default RoleList
