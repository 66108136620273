import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import { RcGroup } from '../../components/RcGroup'
import PortalsList from '../PortalsList/PortalsList'

import { Button } from 'reactstrap'
import { addFloatBox, getTab, removeRCTab } from '../../lib/rcDock-lib'
import AddPortal from '../PortalsList/AddEditPortal'
import { PortalType } from '@digitalworkflow/dwloginclient'
import PortalDetails from './PortalDetails'
import TabClose from '../TabClose'
import { checkLoginPortalAdmin } from '../../utils/checkAdmin'
import { getSize } from '../../utils/getSize'

const ManagePortals = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)
  const portalListRef = useRef<any>(null)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [key, setKey] = useState(0)

  useEffect(() => {
    const permission = checkLoginPortalAdmin()
    setIsAdmin(permission)
  }, [])

  const handleNewPortal = useCallback(() => {
    removeRCTab(dockLayoutRef, 'addPortal')
    portalListRef.current.getAllPortals()
  }, [dockLayoutRef, portalListRef])

  const handleAddPortal = () => {
    const id = 'addPortal'
    addFloatBox(
      dockLayoutRef,
      id,
      getTab(
        id,
        'Add Portal',
        dockLayoutRef,
        () => (
          <AddPortal
            handleNewPortal={handleNewPortal}
            handleRemovePortal={() => removeRCTab(dockLayoutRef, 'addPortal')}
          />
        ),
        510
      )
    )
  }

  const handleUpdatePortal = async (portal: PortalType | undefined) => {
    if (portalListRef && portalListRef.current) {
      await portalListRef.current.getAllPortals()
    }
    if (portal) {
      handleManagePortal(portal)
    }
  }

  const getTabData = (portal: PortalType) => {
    return {
      id: 'tabs' + portal.id,
      title: (
        <>
          <span>{portal.portal_name}</span>
          <TabClose dockLayoutRef={dockLayoutRef} id={'tabs' + portal.id} />
        </>
      ),
      content: (
        <PortalDetails
          id={portal.login_portal_id ?? ''}
          portal_name={portal.portal_name ?? ''}
          handleUpdatePortal={handleUpdatePortal}
        />
      ),
      group: 'close-all'
    }
  }

  const handleManagePortal = useCallback((portal: PortalType) => {
    if (dockLayoutRef && dockLayoutRef.current) {
      const _dockLayout = dockLayoutRef.current
      const newSection: any = {
        id: 'managePortals',
        tabs: [getTabData(portal)],
        group: 'close-all'
      }

      if (_dockLayout.state.layout.maxbox?.children) {
        const { children } = _dockLayout.state.layout.maxbox
        if (children.length > 0) {
          const panelData = _dockLayout.find('portalsSection')
          _dockLayout.dockMove(panelData as any, null, 'maximize')
        }
      }

      if (!_dockLayout.find('tabs' + portal.id)) {
        if (_dockLayout.find('managePortals')) {
          _dockLayout.dockMove(getTabData(portal), 'managePortals', 'middle')
        } else {
          _dockLayout.dockMove(newSection, 'portalsSection', 'right')
        }
      } else {
        // updating the same tab
        _dockLayout.updateTab('tabs' + portal.id, getTabData(portal))
      }
    }
  }, [])

  const portalTab = {
    id: 'portals',
    title: 'Portals',
    content: (
      <PortalsList
        isAdmin={isAdmin}
        ref={portalListRef}
        handleManagePortal={handleManagePortal}
      />
    ),
    group: 'close-all'
  }

  const defaultLayout: any = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          size: getSize(1680, 720),
          mode: 'vertical',
          children: [
            {
              tabs: [
                {
                  id: 'description',
                  title: 'MANAGE PORTALS',
                  content: (
                    <div className='p-2'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='mt-1'>
                          <h6>Portals</h6>
                        </div>
                        {isAdmin && (
                          <Button
                            type='button'
                            color='add'
                            className='btn-sm mb-1'
                            onClick={handleAddPortal}
                          >
                            + Add New Portal
                          </Button>
                        )}
                      </div>
                      <p className='descripton'>
                        The Portals page displays a list of portals and provides
                        information such as Name, Login Portal ID and Success
                        Redirection URL. This would facilitate managing portals
                        and their members.
                      </p>
                    </div>
                  ),
                  group: 'headless',
                  size: 'auto'
                }
              ]
            }
          ]
        },
        {
          mode: 'horizontal',
          children: [
            {
              mode: 'horizontal',
              children: [
                {
                  id: 'portalsSection',
                  tabs: [portalTab],
                  group: 'close-all'
                }
              ]
            }
          ]
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [isAdmin])

  return (
    <RCDockLayout
      key={key}
      dockLayoutRef={dockLayoutRef}
      defaultLayout={defaultLayout}
      groups={RcGroup}
    />
  )
}
export default ManagePortals
