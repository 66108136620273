/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import { AuthService, UserService } from '@digitalworkflow/dwloginclient'
const authService = AuthService.instance()
UserService.setAuthServiceInstance(authService)
const userService = new UserService()

interface UserOption {
  value: string
  label: string
  email: string
}

interface CopyWorkgroupsFromProps {
  userId: string
  cloneWorkgroupsFrom: (fromUserId: string, toUserId: string) => void
  closeCopyFrom: () => void
}

interface FormValues {
  selectedUser: string
}

const initialValues: FormValues = {
  selectedUser: ''
}

const validationSchema = Yup.object().shape({
  selectedUser: Yup.string().required('A user should be selected')
})

const CopyWorkgroupsFrom: React.FC<CopyWorkgroupsFromProps> = ({
  userId,
  cloneWorkgroupsFrom,
  closeCopyFrom
}) => {
  const [users, setUsers] = useState<UserOption[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true)
      const result = await userService.getAllUser()
      if (result && !result.is_error) {
        const options: UserOption[] =
          result.data?.map((user) => ({
            value: user.id ?? '',
            label: user.first_name + ' ' + user.last_name,
            email: user.email
          })) ?? []
        setUsers(options)
      }
      setIsLoading(false)
    }
    fetchUsers()
  }, [])

  return (
    <div className='p-2'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: FormValues) => {
          cloneWorkgroupsFrom(values.selectedUser, userId)
        }}
      >
        {({ handleSubmit, values, setFieldValue, setFieldTouched, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <Row className='mb-2'>
              <Col>
                <SelectDropdown
                  options={users}
                  name='selectedUser'
                  placeholder='Select User'
                  isLoading={isLoading}
                  value={users.find(
                    (user: any) => user.value === values.selectedUser
                  )}
                  onChange={(value: any) => {
                    setFieldTouched('selectedUser', true)
                    setFieldValue('selectedUser', value.value)
                  }}
                  filterOption={(option, inputValue) => {
                    const userOption = option.data as UserOption
                    return (
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      userOption.email
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  }}
                />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col className='d-flex gap-2 justify-end'>
                <Button
                  type='submit'
                  color='edit'
                  className='btn-sm'
                  disabled={!dirty}
                >
                  <i className='fal fa-check me-2' />
                  Apply
                </Button>
                <Button
                  type='button'
                  color='cancel'
                  className='btn-sm'
                  onClick={() => closeCopyFrom()}
                >
                  <i className='fa fa-times me-2' />
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CopyWorkgroupsFrom
